import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import { Box, Grid } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';
import ReviewCard from '../Components/ReviewCard';
import useWindowDimensions from '../hooks/getWindowDimensions';
import Title from '../Components/Title';
import Desc from '../Components/Desc';
import ResponsiveReviewCards from '../Components/ResponsiveReviewCards';
import { Pagination } from 'swiper/modules';


const SectionReviews = () => {

    const { width } = useWindowDimensions();

    return (
        <SectionBreaker>
            <Grid container rowSpacing={
                width < 899.99 ? 3 :
                    width === 1440 ? 2 :
                        width > 1440 ? 5 : 4
            } flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                    <Title
                        title={'Unsere Referenzen'}
                    />
                </Grid>
                <Grid item>
                    <Desc
                        desc={"Entdecken Sie, warum unsere Kunden uns lieben"}
                    />
                </Grid>
                <Grid item>
                    {
                        width > 1000 ? (
                            <Box px={4} style={{ width: (width > 899.98 ? '900px' : '190px'), margin: 'auto' }} pt={width === 1440 && 6}>
                                <Swiper
                                    effect={'cards'}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    grabCursor={true}
                                    modules={[EffectCards, Pagination]}

                                    className="ReviewSwiper"
                                >
                                    <SwiperSlide>
                                        <ReviewCard
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ReviewCard
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ReviewCard
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ReviewCard
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ReviewCard
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                </Swiper>
                            </Box>
                        ) : (
                            <Box px={4} style={{ margin: 'auto' }}>
                                <Swiper
                                    spaceBetween={4}
                                    pagination={true}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        600: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        800: {
                                            slidesPerView: 1,
                                            spaceBetween: 40,
                                        },
                                        1200: {
                                            slidesPerView: 3,
                                            spaceBetween: 50,
                                        },
                                        1440: {
                                            slidesPerView: 4.5,
                                            spaceBetween: 20,
                                        }
                                    }}
                                    className="responsiveSwiper">
                                    <SwiperSlide>
                                        <ResponsiveReviewCards
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ResponsiveReviewCards
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ResponsiveReviewCards
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ResponsiveReviewCards
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ResponsiveReviewCards
                                            name={'Sonja Lörtscher'}
                                            desc={'Ein Jahr bei uns'}
                                            comment={"Die Beratung zur Krankenversicherung und Lebensversicherung durch die GLN Services GmbH war sehr professionell und hilfreich. Herr Gülen nahm sich die Zeit, um meine individuellen Bedürfnisse und Anforderungen zu verstehen und präsentierte dann die Versicherungslösungen, die perfekt zu mir passten."}
                                        />
                                    </SwiperSlide>
                                </Swiper>
                            </Box>
                        )
                    }
                </Grid>
            </Grid>
        </SectionBreaker>
    )
}

export default SectionReviews