import React from 'react'
import Section1 from '../Sections/Section1'
import SectionServices from '../Sections/SectionServices'
import SectionReviews from '../Sections/SectionReviews'
import SectionAboutus from '../Sections/SectionAboutus'
import SectionHelp from '../Sections/SectionHelp'

const Home = () => {
  return (
    <>
    <Section1/>
    <SectionServices/>
    <SectionReviews/>
    <SectionAboutus/>
    <SectionHelp/>
    </>
  
  )
}

export default Home