import styled from '@emotion/styled'
import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import { Grid } from '@mui/material'
// import { ReactComponent as Videoplayer } from '../Assets/icons/videoplayer.svg'
import { ReactComponent as FamilyIcon } from '../Assets/icons/familyicon.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'
import Container from '../Components/Container'
import { Link } from 'react-router-dom'

const LineargradientTitle = styled.span`
    display:block;
    color: #8DAAE4;
    font-size:56px;
    font-weight:600;
    width:100%;
    max-width:700px;
    line-height:75px;
    padding-bottom:10px;


    @media (max-width: 1440px) {
        font-size:40px;
        max-width:500px;
        line-height:60px;
    }

    @media (max-width: 1300px) {
        font-size:30px;
        max-width:300px;
        line-height:50px;
    }

    @media (max-width: 899.98px) {
        font-size:30px;
        max-width:500px;
    }

    @media (max-width: 599.98px) { 
        font-size:30px;
        max-width:360px;
        line-height:28px;
    }
`

// const Title = styled.span`
//     display:block;
//     color:#000000;
//     font-size:56px;
//     font-weight:600;
//     width:100%;
//     max-width:700px;
//     line-height:75px;

//     @media (max-width: 1440px) {
//         font-size:40px;
//         max-width:500px;
//     }

//     @media (max-width: 899.98px) {
//         font-size:30px;
//         max-width:300px;
//     }

//     @media (max-width: 599.98px) { 
//         font-size:30px;
//         max-width:400px;
//         line-height:45px;
//     }


// `

const Desc = styled.span`
    display:block;
    color:#AEAEAE;
    font-size:24px;
    font-weight:400;
    width:100%;
    max-width:800px;
    line-height:37.2px;

    @media (max-width: 1440px) {
        font-size:20px ;
        max-width:600px;
        line-height:30px;
    }

    @media (max-width: 1300px) {
        font-size:20px;
        max-width:300px;
    }

    @media (max-width: 899.98px) {
        font-size:20px;
        max-width:600px;
    }

    @media (max-width: 599.98px) { 
        font-size:17px;
        max-width:350px;
        line-height:25px;
    }
`

const HelpButton = styled.button`
    padding:20px 55px;
    border:none;
    border-radius:50px;
    background: #8DAAE4;
    font-size:22px;
    font-weight:600;
    line-height:27px;
    color:#fff;
    cursor:pointer;
    font-family:Poppins;
    width:100%;
    max-width:500px;

    @media (max-width: 1440px) {
        font-size:18px ;
        padding:15px 40px;
    }

    @media (max-width: 899.98px) {
        font-size:15px;
        padding:15px 30px;
    }

    @media (max-width: 599.98px) { 
        font-size:15px;
        padding:15px 30px;
    }

   
`

// const VideoButton = styled.button`
//     padding:20px 55px;
//     border:none;
//     border-radius:50px;
//     background-color:#fff;
//     font-size:22px;
//     font-weight:600;
//     line-height:27px;
//     background-image: linear-gradient(93.86deg, #B3B0E7 2.82%, #8DAAE4 61.27%, #B5F4FB 104.79%);
//     -webkit-background-clip: text;
//     background-clip: text;
//     color: transparent;
//     cursor:pointer;
//     width:100%;

//     @media (max-width: 1440px) {
//         font-size:18px;
//         padding:15px 40px;
//     }

//     @media (max-width: 899.98px) {
//         font-size:15px;
//         padding:15px 18px;
//     }

//     @media (max-width: 599.98px) { 
//         font-size:15px;
//         padding:15px 18px;
//     }
// `

const Section1 = () => {

    const { width } = useWindowDimensions()
    return (
        <SectionBreaker>
            <Container>
                <Grid container justifyContent={width > 899.99 ? "space-between" : "center"} margin={width < 899.99 && 'auto'} alignItems={'center'} pt={width > 899.99 ? 7 : 3}>
                    <Grid item >
                        <Grid container rowSpacing={3} px={width <= 899.99 ? 3 : width <= 1000 ? 5 : undefined} flexDirection={'column'} >
                            <Grid item>
                                <LineargradientTitle>Unkompliziert, Direkt, Vertrauensvoll!</LineargradientTitle>
                            </Grid>
                            <Grid item>
                                <Desc>
                                    Unser Slogan spiegelt nicht nur unsere Werte wider, sondern auch unsere Arbeitsweise bei GLN Services GmbH. Wir verstehen, dass Versicherungsfragen oft komplex sein können,
                                    daher setzen wir auf klare und direkte Kommunikation, um unseren Kunden eine unkomplizierte Erfahrung zu bieten.
                                </Desc>
                            </Grid>
                            <Grid item>
                                <Link to='/contact'>
                                    <HelpButton>
                                        Lass uns dir helfen
                                    </HelpButton>
                                </Link>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {
                            width > 899.99 ? (
                                <FamilyIcon style={{ width: "100%", maxWidth: (width < 1300 && "500px"), height: "auto" }} />
                            ) : (
                                <FamilyIcon style={{ width: "100%", maxWidth: "500px", height: "auto" }} />
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </SectionBreaker>
    )
}

export default Section1