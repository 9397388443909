import { Grid } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import { ReactComponent as GLNLogo } from '../Assets/icons/GLNLogo.svg';
import { ReactComponent as GLNLogoservices } from '../Assets/icons/Glnserviceslogo.svg';
// import { Button } from "@chakra-ui/react";
// import { ChevronDownIcon } from "@chakra-ui/icons";
import useWindowDimensions from '../hooks/getWindowDimensions';
import {
    // Menu,
    // MenuButton,
    // MenuList,
    // MenuItem,
    // MenuGroup,
    // MenuDivider,
    // MenuOptionGroup,
    // MenuItemOption,
} from "@chakra-ui/react"
// import HamburgerMenu from './HamburgerMenu';
import Container from './Container';

const NavWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#fff;
    width:100%;
    z-index:1500;
    position:fixed;
`

// const Navlinks = styled.span`
//     display:block;
//     color:#42464D;
//     font-size:18px;
//     font-weight:400;
//     cursor:pointer;
//     line-height:20px;
//     letter-spacing:-0.31px;
    
//     &:hover {
//         background-image: linear-gradient(93.86deg, #B3B0E7 2.82%, #8DAAE4 61.27%, #B5F4FB 104.79%);
//         -webkit-background-clip: text;
//         background-clip: text;
//         color: transparent;
//     }

//     &:after {
//         content: '';
//         display: block;
//         width: 0;
//         height: 2px;
//         background: linear-gradient(90deg, #B3B0E8 0%, #8DAAE4 48.5%, #B5F4FB 100%);
//         transition: width 0.3s;
//     }
    
//     &:hover:after {
//       width: 100%;
//     }

//     .chakra-button {
//         border:none;
//         color:#42464D;
//         font-size: 18px;
//         font-weight: 400;
//         line-height:20px;
//         background-color:transparent;
//         font-family: 'Poppins',sans-serif;
//         &:hover {
//             background-image: linear-gradient(93.86deg, #B3B0E7 2.82%, #8DAAE4 61.27%, #B5F4FB 104.79%);
//             -webkit-background-clip: text;
//             background-clip: text;
//             color: transparent;
//         }
//     }
    
// `

// const RegisterButton = styled.button`
//     padding:10px 45px;
//     border-radius:50px;
//     background-color:transparent;
//     border: 3px solid #B3B0E7;
//     background-image: linear-gradient(93.86deg, #B3B0E7 2.82%, #8DAAE4 61.27%, #B5F4FB 104.79%);
//     -webkit-background-clip: text;
//     background-clip: text;
//     color: transparent;
//     font-size:18px;
//     font-weight:600;
//     line-height:20px;
//     letter-spacing:-0.31px;
//     cursor:pointer;
// `

const Navbar = () => {

    const { width } = useWindowDimensions();

    return (
        <NavWrapper>
            <Container>
                <Grid container justifyContent={"space-between"} alignItems={(width < 899.99 && "center")} px={width < 899.99 && "30px"}>
                    <Grid item>
                        {
                            width > 899.99 ?
                                (
                                    <GLNLogoservices style={{ width: "100%", height: "auto", maxWidth:"130px" }} />
                                ) : (
                                    <GLNLogo style={{ width: "100%", maxWidth: (width > 899.99 ? "130px" : "95px"), height: "auto" }} />
                                )
                        }
                    </Grid>
                    <Grid item >
                        <Grid container xs={'auto'} columnSpacing={4} paddingTop={width > 899.99 ? '35px' : "10px"} justifyContent={'flex-end'} alignItems={'center'}>
                            {
                                width > 899.98 ? (
                                    <>
                                        {/* <Grid item>
                                            <Navlinks>Home</Navlinks>
                                        </Grid>
                                        <Grid item>
                                            <Navlinks>
                                                <Menu>
                                                    <MenuButton as={Button}>
                                                        FR
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem>Download</MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            </Navlinks>
                                        </Grid> */}
                                    </>
                                ) : (
                                    <>
                                    {/* // <Grid item xs={10} >
                                    //     <div >
                                    //         <HamburgerMenu />
                                    //     </div>
                                    // </Grid> */}
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </NavWrapper>
    )
}

export default Navbar