import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Ratestar } from '../Assets/icons/starrate.svg'
import { ReactComponent as Person } from '../Assets/icons/person.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'

const Name = styled.span`
    display:block;
    color:#B3B0E7;
    font-size:24px;
    font-weight:600;
    line-height:38.64px;
    text-align:center;

    @media (max-width: 1440px) {
        font-size:18px;
    }

    @media (max-width: 899.98px) {
        font-size:15px;
    }

    @media (max-width: 599.98px) { 
        font-size:15px;
    }
`

const Desc = styled.span`
    color:#999999;
    font-size:16px;
    font-weight:600;
    line-height:25.76px;
    text-align:center;

    @media (max-width: 1440px) {
        font-size:15px;
    }

    @media (max-width: 899.98px) {
        font-size:11px;
    }

    @media (max-width: 599.98px) { 
        font-size:11px;
    }
`

const Comment = styled.span`
    display:block;
    color:#C0C0C0;
    font-size:18px;
    font-weight:500;
    line-height:28.98px;
    text-align:center;
    width:100%;
    max-width:700px;

    @media (max-width: 1440px) {
        font-size:13px;
    }

    @media (max-width: 899.98px) {
        font-size:12px;
        line-height:20px;
    }

    @media (max-width: 599.98px) { 
        font-size:12px;
    }
`

const ReviewCard = ({ svg, name, desc, comment }) => {

    const { width } = useWindowDimensions();
    return (
        <Grid container rowSpacing={
            width < 899.99 ? 3 :
                width > 1440 ? 3 :
                    width === 1440 ? 1.5 : undefined
        }
            padding={4.5} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Grid item>
                {width === 1440 ? (
                    <Person style={{ width: "100%", height: "auto", maxWidth: "70px" }} />
                ) : width > 899.99 ? (
                    <Person />
                ) : (
                    <Person style={{ width: "100%", height: "auto", maxWidth: "50px" }} />
                )}
            </Grid>

            <Grid item>
                <Grid container flexDirection={'column'} textAlign={'center'}>
                    <Grid item>
                        <Name>{name}</Name>
                    </Grid>
                    <Grid item>
                        <Desc>{desc}</Desc>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Comment>{comment}</Comment>
            </Grid>
            <Grid item>
                <Grid container pt={width === 1440 && 2} columnSpacing={2}>
                    <Grid item>
                        <Ratestar style={{ width: "100%", height: "auto", maxWidth: (width > 1440 ? "100px" : "20px") }} />
                    </Grid>
                    <Grid item>
                        <Ratestar style={{ width: "100%", height: "auto", maxWidth: (width > 1440 ? "100px" : "20px") }} />
                    </Grid>
                    <Grid item>
                        <Ratestar style={{ width: "100%", height: "auto", maxWidth: (width > 1440 ? "100px" : "20px") }} />
                    </Grid>
                    <Grid item>
                        <Ratestar style={{ width: "100%", height: "auto", maxWidth: (width > 1440 ? "100px" : "20px") }} />
                    </Grid>
                    <Grid item>
                        <Ratestar style={{ width: "100%", height: "auto", maxWidth: (width > 1440 ? "100px" : "20px") }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ReviewCard