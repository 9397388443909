import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Ratestar } from '../Assets/icons/starrate.svg'
import { ReactComponent as Person } from '../Assets/icons/person.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'


const Box = styled.div`
    display:flex;
    padding:15px 15px;
    background-color:#fff;
    border-radius:20px;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
    width:100%;
    max-width:330px;
    margin-left:20px;
    margin-top:30px;
`

const Name = styled.span`
    display:block;
    color:#B3B0E7;
    font-size:24px;
    font-weight:600;
    line-height:38.64px;
    text-align:center;

    @media (max-width: 899.98px) {
        font-size:13px;
    }

    @media (max-width: 599.98px) { 
        font-size:18px;
    }
`

const Desc = styled.span`
    color:#999999;
    font-size:16px;
    font-weight:600;
    line-height:25.76px;
    text-align:center;
  

    @media (max-width: 899.98px) {
        font-size:11px;
    }

    @media (max-width: 599.98px) { 
        font-size:14px;
    }
`

const Comment = styled.span`
    display:block;
    color:#C0C0C0;
    font-size:18px;
    font-weight:500;
    line-height:28.98px;
    text-align:center;
    width:100%;
    max-width:300px;

    @media (max-width: 899.98px) {
        font-size:12px;
    }

    @media (max-width: 599.98px) { 
        font-size:12px;
        line-height:18px;
    }
`
const ResponsiveReviewCards = ({ svg, name, desc, comment }) => {

    const { width } = useWindowDimensions();
    return (
        <Box>
            <Grid container rowSpacing={1.5} padding={2} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                    {
                        width > 899.99 ? (
                            <Person />
                        ) : (
                            <Person style={{ width: "100%", height: "auto", maxWidth: "80px" }} />
                        )
                    }
                </Grid>
                <Grid item>
                    <Grid container flexDirection={'column'} textAlign={'center'}>
                        <Grid item>
                            <Name>{name}</Name>
                        </Grid>
                        <Grid item>
                            <Desc>{desc}</Desc>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Comment>{comment}</Comment>
                </Grid>
                <Grid item>
                    <Grid container columnSpacing={1}>
                        <Grid item>
                            <Ratestar style={{width:"100%", height:"auto", maxWidth:(width < 899.99 && "20px")}} />
                        </Grid>
                        <Grid item>
                            <Ratestar style={{width:"100%", height:"auto", maxWidth:(width < 899.99 && "20px")}}/>
                        </Grid>
                        <Grid item>
                            <Ratestar style={{width:"100%", height:"auto", maxWidth:(width < 899.99 && "20px")}}/>
                        </Grid>
                        <Grid item>
                            <Ratestar style={{width:"100%", height:"auto", maxWidth:(width < 899.99 && "20px")}}/>
                        </Grid>
                        <Grid item>
                            <Ratestar style={{width:"100%", height:"auto", maxWidth:(width < 899.99 && "20px")}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ResponsiveReviewCards