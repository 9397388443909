import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Title from '../Components/Title'
import Desc from '../Components/Desc'
import { ReactComponent as Help } from '../Assets/icons/Help.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'
import Container from '../Components/Container'
import { Link } from 'react-router-dom'

const HelpButton = styled.button`
    padding:20px 55px;
    border:none;
    border-radius:50px;
    background: #8DAAE4;
    font-size:22px;
    line-height:27px;
    font-weight:600;
    color:#fff;
    cursor:pointer;
    width:100%;
    max-width:500px;
    font-family:Poppins;

    @media (max-width: 1440px) {
        font-size:16px;
        padding:15px 20px;
        max-width:370px;
    }

    @media (max-width: 899.98px) {
        font-size:15px;
        padding:15px 25px;
    }

    @media (max-width: 599.98px) { 
        font-size:15px;
        padding:15px 25px;
        max-width:none;
    }
`

const SectionHelp = () => {

    const { width } = useWindowDimensions();

    return (
        <SectionBreaker>
            <Container >
                <Grid container style={{ display: "flex", justifyContent: (width < 1300 ? "center" : "space-between") }} flexDirection={width < 899.99 && "column-reverse"}>
                    <Grid item>
                        <Grid container rowSpacing={width > 899.99 ? 4 : 3} flexDirection={'column'} px={width < 899.99 && 4}>
                            <Grid item>
                                <Title
                                    style={{ textAlign: (width < 899.99 ? "center" : "left") }}
                                    title={'Hier erreichen Sie uns'}
                                />
                            </Grid>
                            <Grid item>
                                <Desc
                                    style={{ textAlign: (width < 899.99 ? "center" : "left"), maxWidth: (width < 1440 ? "850px" : "650px") }}
                                    desc={"Profitieren Sie von unseren Dienstleistungen und lassen Sie sich beraten."} />
                            </Grid>
                            <Grid item>
                                <div style={{ textAlign: (width < 899.99 ? "center" : "left") }}>
                                    <Link to='/contact'>
                                        <HelpButton>Beratung anfordern</HelpButton>
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container pt={{ md: 5 }} justifyContent={'center'}>
                            {
                                width > 899.99 ? (
                                    <Help style={{ width: "100%", height: "auto", maxWidth: "400px", paddingLeft: "10px" }} />
                                ) : (
                                    <Help style={{ width: "100%", height: "auto", maxWidth: "380px", paddingLeft: "10px" }} />
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </SectionBreaker>
    )
}

export default SectionHelp