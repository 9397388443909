import React from 'react'
import SectionBreaker from './SectionBreaker'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { ReactComponent as GLNLogo } from '../Assets/icons/GLNLogo.svg'
// import { ReactComponent as Send } from '../Assets/icons/send.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'
import Container from './Container'
import { ReactComponent as GLNLogoservices } from '../Assets/icons/Glnserviceslogo.svg';

const Wrapper = styled.div`
    padding:50px;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
`

// const Footerdesc = styled.span`
//     display:block;
//     width:100%;
//     max-width:300px;
//     font-size:16px;
//     font-weight:500;
//     color:#C0C0C0;
//     line-height:27.2px;

//     @media (max-width: 1440px) {
//         font-size:16px ;
//         max-width:250px;
//     }

//     @media (max-width: 899.98px) {
//         font-size:16px;
//     }

//     @media (max-width: 599.98px) { 
//         font-size:16px;
//     }
// `

// const FooterTitle = styled.span`
//     display:block;
//     font-size:20px;
//     font-weight:700;
//     color:#8DAAE4;
//     line-height:30px;

//     @media (max-width: 1440px) {
//         font-size:18px !important;
//     }

//     @media (max-width: 899.98px) {
//         font-size:16px;
//     }

//     @media (max-width: 599.98px) { 
//         font-size:16px;
//     }
// `

const FooterLinks = styled.span`
    display:block;
    font-size:18px;
    font-weight:500;
    color:#C0C0C0;
    line-height:30.6px;
    text-align:center;

    @media (max-width: 1440px) {
        font-size:16px;
    }

    @media (max-width: 899.98px) {
        font-size:15px;
    }

    @media (max-width: 599.98px) { 
        font-size:15px;
    }
`

// const FooterText = styled.span`
//     font-size:16px;
//     color:#BCBCBC;

//     @media (max-width: 1440px) {
//         font-size:14px;
//     }

//     @media (max-width: 899.98px) {
//         font-size:14px;
//     }

//     @media (max-width: 599.98px) { 
//         font-size:14px;
//     }
// `

// const InputWrapper = styled.div`
//     border-radius: 25px;
//     width: 100%;
//     padding:3px 3px;
//     border: 2px solid transparent; 
//     background-image: linear-gradient(90deg, #B2B0E7 0%, #93B4E7 49.5%, #ADE5F7 100%);
//     background-origin: border-box;
//     background-clip: content-box, border-box;
//     outline: none; 
//     background-clip: padding-box; 
// `

// const InputFooter = styled.input`
//     padding: 13px 10px;
//     border-radius: 25px;
//     width: calc(100% - 23px);
//     border: 2px solid transparent;

//     &:focus {
//         outline:none;
//     }

//     &::placeholder {
//         color:#BCBCBC;
//         font-family: 'Poppins',sans-serif;
//         padding-left:5px;
//     }

//     @media (max-width: 1440px) {
//         width: calc(100% - 25px);
//     }

//     @media (max-width: 899.98px) {
//         width: calc(100% - 26px);
//     }

//     @media (max-width: 599.98px) { 
//         width: calc(100% - 25px);
//     }
// `

// const Sendbutton = styled.button`
//   position:absolute;
//   top:2%;
//   right:-13px;
//   width:100%;
//   padding:7px;
//   max-width:100px;
//   border:none;
//   background:transparent;
//   cursor:pointer;

//   @media (max-width: 1440px) {
//     top:2%;
//     right:-20px;
//   }

//   @media (max-width: 899.98px) {
//     top:2%;
//     right:35px;
//   }

//    @media (max-width: 599.98px) { 
//     top:2%;
//     right:-5px;
//    }
// `

const Rights = styled.span`
   display:block;
   color:#C0C0C0;
   font-size:18px;
   font-weight:500;
   line-height:30.6px;
   text-align:right;

   @media (max-width: 1440px) {
    font-size:16px;
    }

    @media (max-width: 899.98px) {
        font-size:13px;
        text-align:center;
    }

    @media (max-width: 599.98px) { 
        font-size:11px;
        text-align:center;
        padding-top:30px;
    }
`

const Footer = () => {
    const { width } = useWindowDimensions();
    return (
        <SectionBreaker>
            <Wrapper>
                <Container>
                    <Grid container justifyContent={'center'} alignItems={'center'} >
                        <Grid item xs={6} md={2}>
                            {
                                width > 899.99 ?
                                    (
                                        <GLNLogoservices style={{ width: "100%", height: "auto", maxWidth: (width > 899.99 ? "150px" : "100px") }} />
                                    ) :
                                    (
                                        <GLNLogo style={{ width: "100%", height: "auto", maxWidth: (width > 899.99 ? "150px" : "100px") }} />
                                    )
                            }
                        </Grid>
                        <Grid item xs={6} md={4} >
                            <Grid container justifyContent={width < 899.99 && "center"} alignItems={'center'}>
                                <Grid item xs={12} md={2}>
                                    <FooterLinks>FAQ</FooterLinks>
                                </Grid>
                                <Grid item xs={12} md={3.5}>
                                    <FooterLinks>Impressum</FooterLinks>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FooterLinks>Datenschutz</FooterLinks>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Rights>Copyright © 2024 GLN Services | All Rights Reserved </Rights>
                        </Grid>
                    </Grid>
                </Container>
            </Wrapper>
        </SectionBreaker >
    )
}

export default Footer