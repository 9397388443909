import React from 'react'
import styled from 'styled-components'

const TitleSection = styled.span`
    display:block;
    color: #8DAAE4;
    font-size:50px;
    font-weight:700;
    line-height:50px;
    text-align:center;
    width:100%;
    max-width:800px;

    @media (max-width: 1440px) {
        font-size:35px;
        line-height:40px;
    }

    @media (max-width: 899.98px) {
        font-size:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:30px;
        font-weight:700;
        line-height:30px;
    }
`
const Title = ({title, style}) => {
  return (
    <TitleSection style={{...style}}>{title}</TitleSection>
  )
}

export default Title