import React from 'react'
import styled from 'styled-components'


const Contain = styled.div`
    width:100%;
    max-width:1400px;
    margin:auto;
    
    @media (max-width: 1440px) { 
      max-width:1200px;
    }

    @media (max-width: 1300px) { 
      max-width:1000px;
    }

    @media (max-width: 1199.98px) { 
      max-width:900px;
    }
`

const Container = ({children, style}) => {
  return (
    <Contain style={{...style}}>{children}</Contain>
  )
}

export default Container