import React, { useState } from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { Grid } from '@mui/material'
import { ReactComponent as Contact } from '../Assets/icons/Contactformicon.svg'
import styled from 'styled-components'
import useWindowDimensions from '../hooks/getWindowDimensions'
import axios from 'axios'

const Title = styled.p`
    font-size:56px;
    font-weight:600;
    line-height:77px;
    background: linear-gradient(90deg, #B3B0E7 100%, #8DAAE4 100%);
    -webkit-background-clip: text; /* This ensures the gradient applies only to the text */
    -webkit-text-fill-color: transparent;
    text-align:left;
    margin-bottom:0px;
    

    @media (max-width: 899.98px) {
        font-size:45px;
        line-height:40px;
        margin-bottom:10px;
    }

    @media (max-width: 599.98px) { 
        font-size:45px;
        line-height:40px;
        margin-bottom:10px;
    }
`

const Desc = styled.p`
    font-size:24px;
    font-weight:500;
    line-height:38.4px;
    color:#C0C0C0;
    text-align:left;
    width:100%;
    max-width:650px;
    
    @media (max-width: 899.98px) {
        font-size:18px;
        line-height:27px;
    }

    @media (max-width: 599.98px) { 
        font-size:18px;
        line-height:27px;
    }
`

const SuccessMessage = styled.p`
    font-size:19px;
    font-weight:400;
    color:green;
    text-align:center;
    
    @media (max-width: 899.98px) {
        font-size:18px;
    }

    @media (max-width: 599.98px) { 
        font-size:18px;
    }
`

const Label = styled.label`
    font-size:14px;
    font-weight:500;
    line-height:21px;
    color:#000;
    text-align:left;
    padding-bottom:5px;
`

const Input = styled.input`
    color:#000;
    width:100%;
    height:46px;
    background:#F6F6F6;
    border:none;
    border-radius:50px;
    outline:none;
    text-indent: 20px;
`

const TextArea = styled.textarea`
    color:#000;
    width:100%;
    height:165px;
    background:#F6F6F6;
    border:none;
    border-radius:22px;
    outline:none;
    text-indent: 20px;
    padding-top:20px;
`

const Button = styled.button`
    color:#fff;
    width:100%;
    background: linear-gradient(90deg, #B3B0E7 100%, #8DAAE4 100%);
    border:none;
    padding:16px 30px;
    border-radius:81px;
    font-size:20px;
    font-weight:600;
    line-height:27px;
    cursor:pointer;

    @media (max-width: 899.98px) {
        font-size:18px;
    }

    @media (max-width: 599.98px) { 
        font-size:18px;
    }
`

const ContactPage = () => {

    const [formData, setFormData] = useState({
        vorname: '',
        name: '',
        tel: '',
        anliegen: ''
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            vorname: formData.vorname,
            name: formData.name,
            tel: formData.tel,
            anliegen: formData.anliegen
        }

        console.log(data);
        try {
            const response = await axios.post("https://gln.kutiza.com/send-email", data);
            console.log('Response:', response.data);
            setSuccessMessage("Erfolgreich gesendet!")
        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
                console.error('Status Code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };

    const { width } = useWindowDimensions();

    return (
        <SectionBreaker>
            <Container>
                <Grid container alignItems={'center'} rowSpacing={6} columnSpacing={0}>
                    <Grid item xs={12} md={6}>
                        <Grid container padding={width < 899.99 && "0px 30px"} flexDirection={'column'}>
                            <Grid item xs={12}>
                                <Title>Lassen Sie uns sprechen.</Title>
                            </Grid>
                            <Grid item xs={12}>
                                <Desc>Haben Sie eine große Idee oder eine Marke zu entwickeln und benötigen Unterstützung?
                                    Dann melden Sie sich, wir würden gerne mehr über Ihr Projekt erfahren und Hilfe anbieten.</Desc>
                            </Grid>
                            {
                                width > 899.99 ? (
                                    <Grid item xs={12} paddingLeft={'100px'}>
                                        <Contact />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} paddingLeft={'40px'} >
                                        <Contact width={"100%"} height={'auto'} style={{ maxWidth: "300px" }} />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <form onSubmit={handleSubmit}>
                            <Grid container padding={width < 899.99 && "0px 30px"} flexDirection={'column'} rowSpacing={4}>
                                <Grid item>
                                    <Grid container flexDirection={'column'} rowSpacing={2}>
                                        <Grid item>
                                            <Label>Vorname</Label>
                                        </Grid>
                                        <Grid item>
                                            <Input type='text' name='vorname' onChange={handleChange} required />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container flexDirection={'column'} rowSpacing={2}>
                                        <Grid item>
                                            <Label>Name</Label>
                                        </Grid>
                                        <Grid item>
                                            <Input type='text' name='name' onChange={handleChange} required />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container flexDirection={'column'} rowSpacing={2}>
                                        <Grid item>
                                            <Label>Telefon</Label>
                                        </Grid>
                                        <Grid item>
                                            <Input type='tel' name='tel' onChange={handleChange} required />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container flexDirection={'column'} rowSpacing={2}>
                                        <Grid item>
                                            <Label>Anliegen</Label>
                                        </Grid>
                                        <Grid item>
                                            <TextArea type='text' name='anliegen' onChange={handleChange} required />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button type='submit'>Absenden</Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <SuccessMessage>{successMessage}</SuccessMessage>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </SectionBreaker>
    )
}

export default ContactPage