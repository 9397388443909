import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import { Grid } from '@mui/material'
// import { ReactComponent as Arrow } from '../Assets/icons/moreabouticon.svg'
import Title from '../Components/Title'
import Desc from '../Components/Desc'
import useWindowDimensions from '../hooks/getWindowDimensions'

// const Showmorelink = styled.a`
//     display:block;
//     color: #8DAAE4;
//     font-size:30px;
//     font-weight:500;
//     line-height:70px;
//     cursor:pointer;
//     text-decoration:none;

//     @media (max-width: 1440px) {
//         font-size:17px;
//     }

//     @media (max-width: 899.98px) {
//         font-size:16px;
//     }

//     @media (max-width: 599.98px) { 
//         font-size:18px;
//     }

    
// `

const SectionAboutus = () => {

    const { width } = useWindowDimensions()

    return (
        <SectionBreaker>
            <Grid container pt={width > 899.99 && 6} px={width < 899.99 && "35px"} rowSpacing={width > 899 ? 4 : 3} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                    <Title
                        style={{ maxWidth: (width < 899.99 && "350px") }}
                        title={'Entdecken Sie, was wir besser machen'}
                    />
                </Grid>
                <Grid item>
                    <Desc
                        style={{ maxWidth: (width < 899.99 && "400px") }}
                        desc={"Dank umfangreicher Partnerschaften mit Versicherungsgesellschaften, haben wir die Möglichkeit, immer das Beste für unsere Kunden herauszuholen"}
                    />
                </Grid>
                {/* <Grid item>
                    <Showmorelink>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                LESEN MEHR
                            </Grid>
                            <Grid item>
                                <Arrow style={{ paddingTop: (width < 899.99 ? "30px" : "25px"), width: "100%", height: "auto", maxWidth: (width < 899.99 && "20px") }} />
                            </Grid>
                        </Grid>
                    </Showmorelink>
                </Grid> */}
            </Grid>
        </SectionBreaker>
    )
}

export default SectionAboutus