import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import { Grid } from '@mui/material'
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import SwiperCard from '../Components/SwiperCard';
import Title from '../Components/Title';
import Desc from '../Components/Desc';
import { ReactComponent as FinancialPlanning } from '../Assets/icons/financialplanning.svg';
// import { ReactComponent as Mortages } from '../Assets/icons/mortages.svg';
// import { ReactComponent as Insurance } from '../Assets/icons/insurance.svg';
// import { ReactComponent as Prevention } from '../Assets/icons/prevention.svg';
// import { ReactComponent as TaxAdvice } from '../Assets/icons/tax.svg';
import useWindowDimensions from '../hooks/getWindowDimensions';
import styled from 'styled-components';
// import { ReactComponent as Arrow } from '../Assets/icons/moreabouticon.svg'

// const Showmorelink = styled.a`
//     display:block;
//     color: #8DAAE4;
//     font-size:30px;
//     font-weight:500;
//     line-height:70px;
//     cursor:pointer;
//     text-decoration:none;

//     @media (max-width: 1440px) {
//         font-size:17px;
//     }

//     @media (max-width: 899.98px) {
//         font-size:16px;
//     }

//     @media (max-width: 599.98px) { 
//         font-size:18px;
//     } 
// `

const ServiceTitle = styled.span`
    display:block;
    font-size:35px;
    font-weight:600;
    line-height:56.35px;
    text-align:center;
    color: #8DAAE4;

    @media (max-width: 1440px) {
        font-size:22px;
    }

    @media (max-width: 899.98px) {
        line-height:50px;
    }

    @media (max-width: 599.98px) { 
        line-height:45px;
        padding-top:5px;
    }
`

const List = styled.span`
    display:block;
    font-size:27px;
    font-weight:500;
    line-height:48.06px;
    color:#C0C0C0;

    @media (max-width: 1440px) {
        font-size:16px;
    }

    @media (max-width: 899.98px) {
        line-height:45px;
    }

    @media (max-width: 599.98px) { 
        line-height:40px;
    }
`

const SectionServices = () => {

    const { width } = useWindowDimensions();

    // const [swiper, setSwiper] = useState(null);

    // const handleSlideChange = () => {
    //     if (swiper) {
    //         const slides = swiper.slides;
    //         for (let i = 0; i < slides.length; i++) {
    //             slides[i].classList.remove('active');
    //         }
    //         slides[swiper.activeIndex].classList.add('active');
    //     }
    // };

    return (
        <SectionBreaker>
            <Grid container rowSpacing={width < 899.99 ? 3 : width > 1440 ? 5 : width === 1440 ? 3 : 4} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                    {
                        width < 899.99 ? (
                            <>
                                <Title
                                    title={<><span>Unsere</span><br />Dienstleistungen</>}
                                />
                            </>
                        ) : (
                            <>
                                <Title
                                    title={'Unsere Dienstleistungen'}
                                />
                            </>
                        )
                    }

                </Grid>
                <Grid item>
                    <Desc
                        desc={'Bei GLN Services GmbH können Sie eine umfassende Unterstützung in allen Belangen rund um Ihren Versicherungsbedarf erwarten. Zunächst analysieren wir gemeinsam mit Ihnen Ihre individuellen Anforderungen ...'}
                    />
                </Grid>
                {/* <Grid item>
                    <Showmorelink>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                LESEN MEHR
                            </Grid>
                            <Grid item>
                                <Arrow style={{ paddingTop: (width < 899.99 ? "30px" : "25px"), width: "100%", height: "auto", maxWidth: (width < 899.99 && "20px") }} />
                            </Grid>
                        </Grid>
                    </Showmorelink>
                </Grid> */}
            </Grid>
            {/* <Box pt={width > 899.99 && 7} style={{ margin: 'auto' }}>
                <Swiper
                    spaceBetween={4}
                    initialSlide={2}
                    slideToClickedSlide={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.3,
                            spaceBetween: 20,
                        },
                        600: {
                            slidesPerView: 1.5,
                            spaceBetween: 20,
                        },
                        800: {
                            slidesPerView: 2.5,
                            spaceBetween: 40,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                        1600: {
                            slidesPerView: 5,
                            spaceBetween: 50,
                        },
                        1800: {
                            slidesPerView: 4.8,
                            spaceBetween: 5,
                        }
                    }}
                    className="testimonialSwiper"
                    centeredSlides={true}
                    onSlideChange={handleSlideChange}
                    onSwiper={setSwiper}>
                    <SwiperSlide>
                        <SwiperCard
                            svg={width > 899.99 ? (<TaxAdvice />) : (<TaxAdvice style={{ width: "100%", height: "auto", maxWidth: "300px" }} />)}
                            title={'Tax Advice'}
                            desc={'Regardless of whet her you invest in stocks or real estate, use our tax advice to enjoy tax advantages.'}

                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperCard
                            svg={width > 899.99 ? (<Prevention />) : (<Prevention style={{ width: "100%", height: "auto", maxWidth: "300px" }} />)}
                            title={'Prevention'}
                            desc={'Regardless of whet her you invest in stocks or real estate, use our tax advice to enjoy tax advantages.'}

                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperCard
                            svg={width > 899.99 ? (<FinancialPlanning />) : (<FinancialPlanning style={{ width: "100%", height: "auto", maxWidth: "300px" }} />)}
                            title={'Financial Planning'}
                            desc={'Regardless of whet her you invest in stocks or real estate, use our tax advice to enjoy tax advantages.'}

                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperCard
                            svg={width > 899.99 ? (<Mortages />) : (<Mortages style={{ width: "100%", height: "auto", maxWidth: "300px" }} />)}
                            title={'Mortages'}
                            desc={'Regardless of whet her you invest in stocks or real estate, use our tax advice to enjoy tax advantages.'}

                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperCard
                            svg={width > 899.99 ? (<Insurance />) : (<Insurance style={{ width: "100%", height: "auto", maxWidth: "300px" }} />)}
                            title={'Insurance'}
                            desc={'Regardless of whet her you invest in stocks or real estate, use our tax advice to enjoy tax advantages.'}

                        />
                    </SwiperSlide>

                </Swiper>
            </Box> */}
            <Grid container pt={10} columnSpacing={10} justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                    {
                        width > 899.99 ?
                            (<FinancialPlanning />) : (<FinancialPlanning style={{ width: "100%", height: "auto", maxWidth: "300px" }} />)
                    }
                </Grid>
                <Grid item>
                    <Grid container rowSpacing={3} flexDirection={'column'}>
                        <Grid item>
                            <ServiceTitle>Versicherung / Vorsorge:</ServiceTitle>
                        </Grid>
                        <Grid item>
                            <Grid container rowSpacing={0} flexDirection={'column'}>
                                <Grid item>
                                    <List>- Kranken</List>
                                </Grid>
                                <Grid item>
                                    <List>- Und Sachversicherung</List>
                                </Grid>
                                <Grid item>
                                    <List>- Lebensversicherung</List>
                                </Grid>
                                <Grid item>
                                    <List>- Planung Altersvorsorge</List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SectionBreaker>
    )
}

export default SectionServices