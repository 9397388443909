import React from 'react'
import styled from 'styled-components'

const DescSection = styled.span`
display:block;
color:#AEAEAE;
font-size:28px;
font-weight:500;
text-align:center;
width:100%;
max-width:1200px;
line-height:44.8px;
text-align:center;

  @media (max-width: 1440px) {
    font-size:20px;
    max-width:600px;
    line-height:33px;
  }

  @media (max-width: 899.98px) {
    font-size:20px;
    max-width:350px;
  }

  @media (max-width: 599.98px) { 
    font-size:16px;
    max-width:350px;
    line-height:25px;
  }
`
const Desc = ({desc, style}) => {
  return (
    <DescSection style={{...style}}>{desc}</DescSection>
  )
}

export default Desc